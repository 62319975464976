<template>
    <div class="ytm-container">
        <CourseSettingsModal v-if="showCourseSettingsModal" @reset_modal="showCourseSettingsModal = false" @course_updated="getCourse"/>
        <NewLessonModal v-if="showNewLessonModal" @reset_modal="showNewLessonModal = false" @lesson_created="getCourse"/>
        <NewHomeworkModal v-if="showNewHomeworkModal" @reset_modal="showNewHomeworkModal = false" @homework_created="getCourse"/>
        <StudentInvitationModal v-if="showStudentInvitationModal" @reset_modal="showStudentInvitationModal = false" @student_invited="getCourse"/>
        <Navbar/>
        <div class="ytm-course-head-wrapper" style="margin-bottom: 2.25rem">
            <div v-if="!loading">
                <div style="display: flex; margin-bottom: 1rem">
                    <svg viewBox="0 0 21 35" fill="none" xmlns="http://www.w3.org/2000/svg"
                         class="ytm-course-head-icon"
                         style="margin-left: -0.5rem; margin-right: 0.75rem; height: 1.5rem; top: 0.45rem"
                         @click="$router.push('/courses')"
                    >
                        <path d="M17.5 3L3 17.5L17.5 32" stroke="black" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <p class="ytm-default-text ytm-head-text">
                        {{course.title}}
                    </p>
                    <svg v-if="course.roleId === 1" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg" class="ytm-course-head-icon" @click="showCourseSettingsModal = true">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M21.9248 2.73744C21.1003 -0.912479 15.8997 -0.91248 15.0753 2.73744C14.5409 5.10296 11.8264 6.22736 9.77585 4.93252C6.61199 2.93462 2.93462 6.61199 4.93252 9.77585C6.22736 11.8264 5.10296 14.5409 2.73744 15.0753C-0.912479 15.8997 -0.91248 21.1003 2.73744 21.9248C5.10296 22.4591 6.22736 25.1737 4.93252 27.2242C2.93462 30.388 6.61199 34.0654 9.77585 32.0675C11.8264 30.7727 14.5409 31.8971 15.0753 34.2626C15.8997 37.9125 21.1003 37.9125 21.9248 34.2626C22.4591 31.8971 25.1737 30.7727 27.2242 32.0675C30.388 34.0654 34.0654 30.388 32.0675 27.2242C30.7727 25.1737 31.8971 22.4591 34.2626 21.9248C37.9125 21.1003 37.9125 15.8997 34.2626 15.0753C31.8971 14.5409 30.7727 11.8264 32.0675 9.77585C34.0654 6.61199 30.388 2.93462 27.2242 4.93252C25.1737 6.22736 22.4591 5.10296 21.9248 2.73744ZM18.5 26C22.6421 26 26 22.6421 26 18.5C26 14.3579 22.6421 11 18.5 11C14.3579 11 11 14.3579 11 18.5C11 22.6421 14.3579 26 18.5 26Z" fill="black"/>
                    </svg>
                </div>
                <p v-if="course.description" class="ytm-default-text" style="margin-top: 0.6rem">
                    <b class="ytm-default-text">О курсе:</b> {{course.description}}
                </p>
                <div style="display: flex; flex-wrap: wrap; margin-top: 0.6rem">
                    <div style="flex-grow: 1; display: table">
                        <div class="ytm-course-head-people-line" style="display: table-cell; vertical-align: middle">
                            <svg v-if="course.roleId !== 1" viewBox="0 0 39 33" fill="none" xmlns="http://www.w3.org/2000/svg" class="ytm-course-head-people-icon">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M37 0C38.1046 0 39 0.895431 39 2V18C39 19.1046 38.1046 20 37 20H21.4544C19.9478 20 18.9297 18.327 18.9932 16.8217C18.9977 16.715 19 16.6078 19 16.5C19 12.3579 15.6421 9 11.5 9C10.2562 9 9 8.12211 9 6.87834V2C9 0.895432 9.89543 0 11 0H37ZM4.79173 16.9927C4.79173 13.683 7.47474 11 10.7844 11C14.094 11 16.7771 13.683 16.7771 16.9927V17.5919C16.7771 20.9016 14.094 23.5846 10.7844 23.5846C7.47474 23.5846 4.79173 20.9016 4.79173 17.5919V16.9927ZM16.982 33H4.16299C2.30956 33 -0.111048 32.4796 0.00394854 30.6298C0.0465698 29.9441 0.408664 29.1619 1.32316 28.3306C3.2103 26.6151 5.46888 25.591 6.92922 25.1063C7.57616 24.8916 8.25872 24.8286 8.94036 24.8285L12.0834 24.8286C12.8966 24.8286 13.7111 24.9221 14.4647 25.2278C15.758 25.7527 17.6847 26.7471 19.6008 28.3306C20.7583 29.2872 21.1113 30.1788 21.05 30.933C20.9113 32.6418 18.6965 33 16.982 33Z" fill="black"/>
                            </svg>
                            <svg v-else-if="course.isIndividual" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" class="ytm-course-head-people-icon">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.7844 0C7.47474 0 4.79173 2.68301 4.79173 5.99266V6.59193C4.79173 9.90159 7.47474 12.5846 10.7844 12.5846C14.094 12.5846 16.7771 9.90159 16.7771 6.59193V5.99266C16.7771 2.68301 14.094 0 10.7844 0ZM4.16299 22H16.982C18.6965 22 20.9113 21.6418 21.05 19.933C21.1113 19.1788 20.7583 18.2872 19.6008 17.3306C17.6847 15.7471 15.758 14.7527 14.4647 14.2278C13.7111 13.9221 12.8966 13.8286 12.0834 13.8286L8.94036 13.8285C8.25872 13.8286 7.57616 13.8916 6.92922 14.1063C5.46888 14.591 3.2103 15.6151 1.32316 17.3306C0.408664 18.1619 0.0465698 18.9441 0.00394854 19.6298C-0.111048 21.4796 2.30956 22 4.16299 22Z" fill="black"/>
                            </svg>
                            <svg v-else viewBox="0 0 32 22" fill="none" xmlns="http://www.w3.org/2000/svg" class="ytm-course-head-people-icon">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.79173 5.99266C4.79173 2.68301 7.47474 0 10.7844 0C14.094 0 16.7771 2.68301 16.7771 5.99266V6.59193C16.7771 9.90159 14.094 12.5846 10.7844 12.5846C7.47474 12.5846 4.79173 9.90159 4.79173 6.59193V5.99266ZM16.982 22H4.16299C2.30956 22 -0.111048 21.4796 0.00394854 19.6298C0.0465698 18.9441 0.408664 18.1619 1.32316 17.3306C3.2103 15.6151 5.46888 14.591 6.92922 14.1063C7.57616 13.8916 8.25872 13.8286 8.94036 13.8285L12.0834 13.8286C12.8966 13.8286 13.7111 13.9221 14.4647 14.2278C15.758 14.7527 17.6847 15.7471 19.6008 17.3306C20.7583 18.2872 21.1113 19.1788 21.05 19.933C20.9113 21.6418 18.6965 22 16.982 22ZM22.2643 19.3045C22.2592 19.3666 22.2514 19.427 22.241 19.4856H28.2428C29.6389 19.4856 31.4424 19.1939 31.5554 17.8024C31.6053 17.1883 31.3179 16.4623 30.3753 15.6834C28.815 14.394 27.2462 13.5842 26.193 13.1569C25.5794 12.9079 24.9162 12.8317 24.254 12.8317L21.6947 12.8317C21.1396 12.8317 20.5838 12.8831 20.057 13.0579C19.3106 13.3056 18.3082 13.7264 17.2851 14.3508C18.3528 14.9133 19.5861 15.6866 20.815 16.7022C21.9725 17.6587 22.3255 18.5503 22.2643 19.3045ZM23.1964 1.57145C20.5014 1.57145 18.3166 3.75618 18.3166 6.45119V6.93917C18.3166 9.63417 20.5014 11.8189 23.1964 11.8189C25.8914 11.8189 28.0761 9.63417 28.0761 6.93917V6.45119C28.0761 3.75618 25.8914 1.57145 23.1964 1.57145Z" fill="black"/>
                            </svg>
                            <span class="ytm-default-text">
                                {{name}}
                            </span>
                        </div>
                    </div>
                    <div
                            v-if="ableToInviteStudents"
                            class="ytm-black-button"
                            style="background: #07A8F0; display: table; color: #FFFFFF; font-weight: 600"
                            @click="showStudentInvitationModal = true"
                    >
                        <svg viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg" style="height: 1.2rem; display: table-cell; vertical-align: middle; margin-right: 0.8rem">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M12.8022 17.619C17.6675 17.619 21.6117 13.6749 21.6117 8.80952C21.6117 3.94416 17.6675 0 12.8022 0C7.93681 0 3.99265 3.94416 3.99265 8.80952C3.99265 13.6749 7.93681 17.619 12.8022 17.619ZM8.79292 21.1427H15.6942C17.4586 21.1427 19.105 22.029 20.0767 23.5018L23.6106 28.8585C25.9135 32.3491 23.41 36.9999 19.2282 36.9999H5.25897C1.07711 36.9999 -1.42633 32.3491 0.876536 28.8585L4.41048 23.5018C5.3821 22.029 7.02852 21.1427 8.79292 21.1427ZM23.8231 16.7382C23.8231 16.0133 24.4107 15.4256 25.1356 15.4256H28.2279V12.3332C28.2279 11.6083 28.8156 11.0207 29.5405 11.0207C30.2654 11.0207 30.853 11.6083 30.853 12.3332V15.4256H33.9452C34.6701 15.4256 35.2577 16.0133 35.2577 16.7382C35.2577 17.4631 34.6701 18.0507 33.9452 18.0507H30.853V21.1427C30.853 21.8676 30.2654 22.4553 29.5405 22.4553C28.8156 22.4553 28.2279 21.8676 28.2279 21.1427V18.0507H25.1356C24.4107 18.0507 23.8231 17.4631 23.8231 16.7382Z" fill="white"/>
                        </svg>
                        <span class="ytm-default-text" style="font-weight: 600; color: #FFFFFF; display: table-cell; vertical-align: middle">
                            Пригласить ученика
                        </span>
                    </div>
                </div>
            </div>
            <div v-else>
                <div class="skeleton skeleton-text-big" style="margin-bottom: 1rem"/>
                <div class="skeleton skeleton-text-default" style="margin-bottom: 0.5rem"/>
                <div class="skeleton skeleton-text-default"/>
            </div>
        </div>
        <div style="display: flex; gap: 0.5rem; margin-bottom: 1.75rem">
            <div style="flex-grow: 1">
                <div class="ytm-tabs-titles">
                    <div
                        :class="{'ytm-tab ytm-default-text ytm-tab-title': true, 'ytm-tab-selected': selectedTab === 'lessons'}"
                        @click="selectTab('lessons')"
                    >
                        Занятия
                    </div>
                    <div
                        :class="{'ytm-tab ytm-default-text ytm-tab-title': true, 'ytm-tab-selected': selectedTab === 'homeworks'}"
                        @click="selectTab('homeworks')"
                    >
                        Домашние&nbspзадания
                    </div>
                    <div
                        :class="{'ytm-tab ytm-default-text ytm-tab-title': true, 'ytm-tab-selected': selectedTab === 'chat'}"
                        @click="selectTab('chat')"
                    >
                        Чат
                    </div>
                </div>
            </div>
            <div v-if="!loading && course && course.roleId === 1 && (selectedTab === 'lessons' || selectedTab === 'homeworks')">
                <div class="ytm-black-button" style="display: table" @click="onCreateButtonClick">
                    <svg viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg" style="height: 1.2rem; display: table-cell; vertical-align: middle; margin-right: 0.8rem">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M20.5 3C20.5 1.61929 19.3807 0.5 18 0.5C16.6193 0.5 15.5 1.61929 15.5 3V14.5H3C1.61929 14.5 0.5 15.6193 0.5 17C0.5 18.3807 1.61929 19.5 3 19.5H15.5V32C15.5 33.3807 16.6193 34.5 18 34.5C19.3807 34.5 20.5 33.3807 20.5 32V19.5H32C33.3807 19.5 34.5 18.3807 34.5 17C34.5 15.6193 33.3807 14.5 32 14.5H20.5V3Z" fill="white"/>
                    </svg>
                    <span class="ytm-default-text" style="font-weight: 600; color: #FFFFFF; display: table-cell; vertical-align: middle">
                        {{selectedTab === 'lessons' ? 'Занятие' : 'Домашнее&nbspзадание'}}
                    </span>
                </div>
            </div>
        </div>
        <div v-show="selectedTab === 'lessons'" style="margin-bottom: 50vh">
            <div v-if="loading">
                <div class="skeleton ytm-lesson-bubble-skeleton"/>
                <div class="skeleton ytm-lesson-bubble-skeleton"/>
            </div>
            <p v-else-if="failed" class="ytm-default-text ytm-onboarding-text">
                Ошибка загрузки :(
            </p>
            <p v-else-if="course.lessons.length === 0" class="ytm-default-text ytm-onboarding-text" style="margin-top: 10vh">
                Пока нет занятий
            </p>
            <div v-else>
                <a
                        v-for="lesson in course.lessons"
                        :key="lesson.lessonId"
                        :href="'/course/' + $route.params.courseLink + '/lesson/' + lesson.lessonId"
                        @click.prevent="$router.push('/course/' + $route.params.courseLink + '/lesson/' + lesson.lessonId)"
                        class="ytm-lesson-bubble-wrapper"
                >
                    <div class="ytm-default-text ytm-lesson-bubble-name" style="display: table">
                        <p style="display: table-cell; vertical-align: middle">
                            {{lesson.topic}}
                        </p>
                    </div>
                    <div v-if="lesson.dtStart" style="display: table; text-align: center">
                        <div style="display: table-cell; vertical-align: middle">
                            <p class="ytm-default-text ytm-ddmmyyyy-in-lesson-card" style="margin-bottom: 0.2rem">
                                {{getDDMMYYYY(lesson.dtStart)}}
                            </p>
                            <p class="ytm-default-text ytm-ddmmyyyy-in-lesson-card">
                                {{getHHMM(lesson.dtStart)}}
                            </p>
                        </div>
                    </div>
                </a>
            </div>
        </div>
        <div v-show="selectedTab === 'homeworks'" style="margin-bottom: 10vh">
            <div v-if="loading">
                <div class="skeleton ytm-lesson-bubble-skeleton"/>
                <div class="skeleton ytm-lesson-bubble-skeleton"/>
            </div>
            <p v-else-if="failed" class="ytm-default-text ytm-onboarding-text">
                Ошибка загрузки :(
            </p>
            <p v-else-if="course.homeworks.length === 0" class="ytm-default-text ytm-onboarding-text" style="margin-top: 10vh">
                Пока нет домашних заданий
            </p>
            <div v-else>
                <a
                    v-for="homework in course.homeworks"
                    :key="homework.id"
                    :href="'/course/' + $route.params.courseLink + '/homework/' + homework.id"
                    @click.prevent="$router.push('/course/' + $route.params.courseLink + '/homework/' + homework.id)"
                    :class="{'ytm-lesson-bubble-wrapper': true, 'ytm-finished-homework': homework.status === 2, 'ytm-hidden-homework': homework.status === 0}"
                >
                    <div class="ytm-default-text ytm-lesson-bubble-name" style="display: flex; align-items: center">
                        <img
                            v-if="homework.status === 0"
                            alt="Скрыто"
                            src="https://img.icons8.com/ios-glyphs/90/000000/hide.png"
                            style="height: 1.5rem; margin-right: 1rem"
                        >
                        <p style="align-items: center">
                            {{homework.topic}}
                        </p>
                    </div>
                </a>
            </div>
        </div>
        <div v-show="selectedTab === 'chat'" style="margin-bottom: 10vh">
            <Chat v-if="course && isSocketReady" :chat_id="'course_' + $route.params.courseLink"/>
        </div>
    </div>
</template>

<script>
import "./skeletons.css";
import "@/styles/style.css";
import Navbar from "@/components/redesign/Navbar.vue";
import {provide, ref} from "vue";
import NewLessonModal from "@/components/CoursePageV2/modals/NewLessonModal.vue";
import CourseSettingsModal from "@/components/CoursePageV2/modals/CourseSettingsModal.vue";
import StudentInvitationModal from "@/components/CoursePageV2/modals/StudentInvitationModal.vue";
import {getDDMMYYYY, getHHMM} from "@/util/datetime-to-str";
import Chat from "@/components/redesign/Chat/Chat.vue";
import NewHomeworkModal from "@/components/CoursePageV2/modals/NewHomeworkModal.vue";
import {useRoute} from "vue-router";

export default {
    name: 'CoursePage',
    components: {NewHomeworkModal, Chat, StudentInvitationModal, CourseSettingsModal, NewLessonModal, Navbar},
    computed: {
        course() {
            return this.$store.getters['course/course'];
        },
        loading() {
            return this.$store.getters['course/loading'] || this.$store.getters['user_info/loading'] || !this.$store.getters['course/course'];
        },
        failed() {
            return this.$store.getters['course/failed'];
        },
        isSocketReady() {
            return this.$store.getters['ws/socketReady'];
        },
        name() {
            if (this.course.roleId === 1) {
                if (!this.course.isIndividual) {
                    if (this.course.students.length === 0) {
                        return 'Нет учеников';
                    } else {
                        return this.course.students.length + ' учеников';
                    }
                } else if (this.course.students.length === 0) {
                    return 'Ученик не добавлен';
                } else {
                    return this.course.students[0].name;
                }
            } else {
                return this.course.teachers[0].name;
            }
        },
        ableToInviteStudents() {
            return this.course && this.course.roleId === 1 && (!this.course.isIndividual || this.course.students.length === 0);
        },
    },
    setup() {
        const route = useRoute();
        const selectedTab = ref('');
        if (route.query.section && (route.query.section === 'lessons' || route.query.section === 'homeworks' || route.query.section === 'chat')) {
            selectedTab.value = route.query.section;
        } else {
            selectedTab.value = 'lessons';
        }
        provide('selectedTab', selectedTab);
        const isChatFocused = ref(selectedTab.value === 'chat');
        provide('is_chat_focused', isChatFocused);
        return {selectedTab, isChatFocused};
    },
    data() {
        return {
            showCourseSettingsModal: false,
            showNewLessonModal: false,
            showNewHomeworkModal: false,
            showStudentInvitationModal: false,
        };
    },
    methods: {
        getDDMMYYYY(dt) {
            return dt ? getDDMMYYYY(dt) : 'Нет даты';
        },
        getHHMM(dt) {
            return dt ? getHHMM(dt) : 'Нет времени';
        },
        getCourse() {
            this.$store.dispatch('course/fetch', this.$route.params.courseLink).catch(err => {
                console.error(err);
            });
        },
        selectTab(tabName) {
            this.selectedTab = tabName;
            this.isChatFocused = tabName === 'chat';
            let query = {};
            if (tabName !== '') {
                query.section = tabName;
            }
            this.$router.replace({query: query});
        },
        onCreateButtonClick() {
            if (this.selectedTab === 'lessons') {
                this.showNewLessonModal = true;
            } else if (this.selectedTab === 'homeworks') {
                this.showNewHomeworkModal = true;
            }
        },
    },
    mounted() {
        this.getCourse();
        this.$store.dispatch('user_info/fetchUserInfo').catch(err => {
            console.error(err);
        });
        this.$store.dispatch('ws/initialize').catch(err => {
            console.error(err);
        });
    },
    beforeUnmount() {
        this.$store.dispatch('course/clear');
        this.$store.dispatch('ws/closeConnection');
    },
};
</script>

<style scoped>
.ytm-course-head-wrapper {
    margin-top: 1.75rem;
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.25);
    border-radius: 1rem;
    padding: 2rem;
}

.ytm-course-head-icon {
    position: relative;
    top: 0.3rem;
    height: 1.85rem;
    min-width: 2rem;
    margin-left: 0.75rem;
    cursor: pointer;
    transition: 0.1s ease-in-out;
}

.ytm-course-head-icon:hover {
    transform: scale(1.03);
}

.ytm-lesson-bubble-skeleton {
    width: 100%;
    height: 4rem;
    border-radius: 1rem;
    margin-bottom: 1rem;
}

.ytm-course-head-people-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ytm-course-head-people-icon {
    position: relative;
    top: 0.1rem;
    height: 1rem;
    margin-right: 0.5rem;
}

.ytm-lesson-bubble-wrapper {
    background: #FFFFFF;
    border: 2px solid #D9D9D9;
    border-radius: 1rem;
    padding: 1rem 1.75rem;
    margin-bottom: 1rem;
    display: flex;
    cursor: pointer;
    transition: 0.2s ease-in-out;
}

.ytm-lesson-bubble-wrapper:hover {
    border: 2px solid #07A8F0;
    transform: scale(1.005);
}

.ytm-lesson-bubble-name {
    margin-right: 1rem;
    flex-grow: 1;
    font-size: 1.5rem;
    font-weight: 600;
}

.ytm-ddmmyyyy-in-lesson-card {
    font-weight: 500;
    font-size: 1rem;
}

@media only screen and (max-width: 600px) {
    .ytm-lesson-bubble-name {
        font-size: 1.15rem;
    }

    .ytm-ddmmyyyy-in-lesson-card {
        font-size: 0.9rem;
    }
}

.ytm-finished-homework {
    opacity: 0.5;
}
</style>