<template>
  <Modal @reset_modal="$emit('reset_modal')">
    <div class="ytm-default-text" style="font-size: 1.75rem; font-weight: 600; text-align: center; margin-bottom: 1.75rem">
      Пригласить ученика
    </div>
    <div v-if="tariffId === -1">
      <p class="ytm-default-text" style="text-align: center; font-size: 1.25rem">
        Чтобы пригласить ученика на курс, нужно выбрать тариф
      </p>
      <div style="margin-top: 2rem; width: 100%; display: flex; justify-content: center">
        <div
            class="ytm-blue-button ytm-default-text"
            style="font-size: 0.9rem; color: #FFFFFF; font-weight: 600"
            @click="$router.push('/account?section=tariff')"
        >
          К выбору тарифа
        </div>
      </div>
    </div>
    <div v-else style="padding-bottom: 1rem">
      <div class="ytm-modal-input">
        <p class="ytm-default-text" style="font-weight: 500; font-size: 0.9rem; margin-bottom: 0.6rem">
          Скопируйте ссылку-приглашение
        </p>
        <InputWithButtonInside v-model="inviteLinkView" :buttonName="inviteLinkButtonName" :disabled="'disabled'" @buttonClick="onCopy"/>
      </div>
      <div>
        <p class="ytm-default-text" style="font-weight: 500; font-size: 0.9rem; margin-bottom: 0.6rem">
          Или введите логин пользователя
        </p>
        <InputWithButtonInside v-model="studentUsername" :buttonName="inviting ? 'Приглашаем...' : 'Пригласить'" @buttonClick="inviteUser"/>
      </div>
      <p v-if="errorMsg" class="ytm-default-text" style="margin-top: 1rem; text-align: center; color: red">
        {{errorMsg}}
      </p>
    </div>
  </Modal>
</template>

<script>
import 'v-calendar/dist/style.css';
import Modal from "@/components/redesign/Modal.vue";
import Input from "@/components/redesign/Form/Input.vue";
import DatetimeGroup from "@/components/redesign/Form/DatetimeGroup.vue";
import axios from "axios";
import {SERVICE_MAIN_URI} from "@/util/api-host";
import {authHeader} from "@/util/auth-header";
import InputWithButtonInside from "@/components/redesign/Form/InputWithButtonInside.vue";

export default {
  name: 'StudentInvitationModal',
  components: {InputWithButtonInside, Modal, Input, DatetimeGroup},
  data() {
    return {
      inviteLink: '',
      studentUsername: '',
      inviteLinkButtonName: 'Скопировать',
      inviting: false,
      errorMsg: '',
    };
  },
  computed: {
    course() {
      return this.$store.getters['course/course'];
    },
    inviteLinkView() {
      return this.inviteLink ? 'https://youteachme.ru/join/' + this.inviteLink : 'Загрузка...'
    },
    tariffId() {
      const userInfo = this.$store.getters['user_info/user_info'];
      if (userInfo && userInfo.tariffState && userInfo.tariffState.tariff) {
        return userInfo.tariffState.tariff.id;
      } else {
        return -1;
      }
    },
  },
  methods: {
    onCopy() {
      navigator.clipboard.writeText(this.inviteLinkView).then(() => {
        this.inviteLinkButtonName = 'Скопировано!';
      }).catch(() => {
        this.inviteLinkButtonName = 'Не удалось скопировать';
      });
    },
    inviteUser() {
      if (this.inviting) {
        return;
      }
      let invitee = this.studentUsername.trim();
      if (!invitee) {
        return;
      }
      this.inviting = true;
      this.errorMsg = '';
      this.$store.dispatch('auth/autoLogin').then(() => {
        axios.post(
            SERVICE_MAIN_URI + '/addUserToCourse',
            {},
            {
              headers: authHeader(),
              params: {
                shortLink: this.$route.params.courseLink,
                invitee: invitee,
                as: 'student',
              },
            },
        ).then(resp => {
          this.inviting = false;
          this.$store.dispatch('course/fetch', this.$route.params.courseLink);
          this.$emit('reset_modal');
        }).catch(err => {
          this.inviting = false;
          this.errorMsg = 'Ошибка: не удалось добавить ученика';
        });
      });
    },
  },
  mounted() {
    this.$store.dispatch('auth/autoLogin').then(() => {
      axios.post(
          SERVICE_MAIN_URI + '/getInviteLink',
          {
            courseLink: this.$route.params.courseLink,
            roleId: 2,
          },
          {
            headers: authHeader(),
          },
      ).then(resp => {
        this.inviteLink = resp.data.out;
      }).catch(err => {
        this.errorMsg = 'Не удалось получить пригласительную ссылку, попробуйте еще раз';
      })
    });
  },
};
</script>

<style scoped>
.ytm-modal-input {
  margin-bottom: 1.25rem;
}
</style>