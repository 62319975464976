<template>
  <div class="ytm-input-with-button-inside" style="display: flex">
    <input
        :value="modelValue"
        @input="updateInput"
        class="ytm-input"
        style="padding: 0"
        :type="type"
        :placeholder="placeholder"
        :disabled="disabled"
    >
    <div style="display: table; margin-left: 0.75rem">
      <div class="ytm-input-button ytm-default-text" style="display: table-cell; vertical-align: middle" @click="onClick">
        <span style="font-size: 0.9rem; font-weight: 600; color: #FFFFFF">
          {{buttonName}}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import "@/styles/style.css";

export default {
  name: 'InputWithButtonInside',
  props: {
    modelValue: {},
    type: {type: String},
    buttonName: {type: String},
    disabled: {type: String},
    placeholder: {type: String},
  },
  methods: {
    updateInput(event) {
      this.$emit('update:modelValue', event.target.value);
    },
    onClick(event) {
      this.$emit('buttonClick', event);
    },
  },
};
</script>

<style scoped>
.ytm-input-with-button-inside {
  width: 100%;
  padding: 0.5rem 1.05rem;
  background: #F3F3F3;
  border-radius: 0.6rem;
  border: none;
  overflow: hidden;
}

.ytm-input {
  width: 100%;
  background: #F3F3F3;
  border: none;
  font-family: 'Montserrat', sans-serif;
  font-size: 1rem;
  font-style: normal;
  line-height: 116.6%;
  font-weight: 400;
  overflow: hidden;
}

.ytm-input:focus {
  outline: none;
}

.ytm-input-button {
  padding: 0.25rem 1.05rem;
  border-radius: 0.6rem;
  background: #07A8F0;
  cursor: pointer;
  user-select: none;
  transition: 0.2s ease-in-out;
}

.ytm-input-button:hover {
  transform: scale(1.03);
}
</style>