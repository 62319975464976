<template>
  <Modal @reset_modal="$emit('reset_modal')">
    <div class="ytm-default-text" style="font-size: 1.75rem; font-weight: 600; text-align: center; margin-bottom: 1.75rem">
      Изменить настройки курса
    </div>
    <div v-if="tariffId === -1">
      <p class="ytm-default-text" style="text-align: center; font-size: 1.25rem">
        Чтобы изменить настройки курса, нужно выбрать тариф
      </p>
      <div style="margin-top: 2rem; width: 100%; display: flex; justify-content: center">
        <div
            class="ytm-blue-button ytm-default-text"
            style="font-size: 0.9rem; color: #FFFFFF; font-weight: 600"
            @click="$router.push('/account?section=tariff')"
        >
          К выбору тарифа
        </div>
      </div>
    </div>
    <div v-else>
      <InputGroup class="ytm-modal-input" v-model="formData.title" title="Название" placeholder="Название курса" :focus="true" @keyup.enter="editCourse"/>
      <InputGroup class="ytm-modal-input" v-model="formData.description" title="Описание" placeholder="Описание курса" @keyup.enter="editCourse"/>
      <InputGroup class="ytm-modal-input" v-model="formData.costPerHour" type="number" title="Стоимость часа" placeholder="Стоимость часа занятий" @input="onCostInput" @keyup.enter="editCourse"/>
      <p v-if="errorMsg" class="ytm-default-text" style="margin-top: 1rem; margin-bottom: -1rem; text-align: center; color: red">
        {{errorMsg}}
      </p>
      <div style="margin-top: 2rem; width: 100%; display: flex; justify-content: center">
        <div
            class="ytm-blue-button ytm-default-text"
            style="font-size: 0.9rem; color: #FFFFFF; font-weight: 600"
            @click="editCourse"
        >
          Сохранить
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import 'v-calendar/dist/style.css';
import Modal from "@/components/redesign/Modal.vue";
import InputGroup from "@/components/redesign/Form/InputGroup.vue";
import axios from "axios";
import {SERVICE_MAIN_URI} from "@/util/api-host";
import {authHeader} from "@/util/auth-header";
import SelectGroup from "@/components/redesign/Form/SelectGroup.vue";
import DatetimeGroup from "@/components/redesign/Form/DatetimeGroup.vue";

export default {
  name: 'CourseSettingsModal',
  components: {SelectGroup, Modal, InputGroup, DatetimeGroup},
  data() {
    return {
      formData: {
        title: this.course ? this.course.title : '',
        description: this.course ? this.course.description : '',
        costPerHour: this.course ? this.course.costPerHour : 0,
      },
      editing: false,
      errorMsg: '',
    };
  },
  computed: {
    course() {
      return this.$store.getters['course/course'];
    },
    tariffId() {
      const userInfo = this.$store.getters['user_info/user_info'];
      if (userInfo && userInfo.tariffState && userInfo.tariffState.tariff) {
        return userInfo.tariffState.tariff.id;
      } else {
        return -1;
      }
    },
  },
  methods: {
    onCostInput() {
      this.formData.costPerHour = Math.max(0, Math.round(this.formData.costPerHour));
    },
    editCourse() {
      if (this.editing) {
        return;
      }
      this.formData.title = this.formData.title.trim();
      if (this.formData.title.length === 0) {
        this.errorMsg = 'Заполните, пожалуйста, название';
        return;
      }
      this.formData.description = this.formData.description.trim();
      this.errorMsg = '';
      this.editing = true;
      let payload = {
        title: this.formData.title,
        description: this.formData.description,
        costPerHour: Math.max(0, Math.round(this.formData.costPerHour)),
      };
      this.$store.dispatch('auth/autoLogin').then(() => {
        axios.post(
            SERVICE_MAIN_URI + '/updateCourse',
            payload,
            {
              headers: authHeader(),
              params: {shortLink: this.course.shortLink},
            },
        ).then(() => {
          this.$emit('course_updated');
          this.editing = false;
          this.$emit('reset_modal');
        }).catch(err => {
          if (err.response && err.response.data.message && err.response.data.message.startsWith('tariffError:')) {
            this.errorMsg = err.response.data.message.slice('tariffError: '.length);
          } else {
            this.errorMsg = 'Упс, не удалось сохранить настройки курса';
            console.error(err);
          }
          this.editing = false;
        });
      });
    },
  },
  mounted() {
    if (this.course) {
      this.formData.title = this.course.title || '';
      this.formData.description = this.course.description || '';
      this.formData.costPerHour = this.course.costPerHour || 0;
    }
  },
};
</script>

<style scoped>
.ytm-modal-input {
  margin-bottom: 1.25rem;
}
</style>